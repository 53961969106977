@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap);
/* colors */
:root {
  --sunglow-color: #ffd23f;
  --paradise-pink-color: #ee4266;
  --blue-violet-color: #540d6e;
  --honeydew-white-color: #f3fcf0;
  --pinetree-black-color: #1f271b;
  --cultured-grey-color: #f2f7f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ## title ## */
.title {
  color: black;
}

/* ##### menu bar #### */
.bars .nav-open,
.nav-close {
  background: transparent;
  font-size: 2rem;
  border: none;
  display: block;
  float: inline-start;
  padding: 0.5rem;
}
.nav-open,
.nav-close {
  color: #f3fcf0;
  color: var(--honeydew-white-color);
  transition: color 1.5s ease, opacity 1.5s ease;
  text-decoration: none;
  cursor: pointer;
}
.nav-open:hover,
.nav-close:hover {
  color: #ffd23f;
  color: var(--sunglow-color);
  opacity: 5;
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.nav a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  text-transform: capitalize;
  text-align: center;
  padding: 1rem;
  transition: all 1s linear;
}

.nav a:hover {
  background-color: #4a4b49;
  color: #ffd23f;
  color: var(--sunglow-color);
  transform: translate(1rem);
}

/* ###### profile ####### */

.profile {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, -0.1)),
    #ee4266;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, -0.1)),
    var(--paradise-pink-color);
  padding: 2rem;
}

.profile-img {
  display: block;
  margin: 0 auto;
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  transition: all 1s linear;
}

.profile-img:hover {
  transform: scale(1.1, 1.1);
}

.profile-detail,
h1,
h4,
p {
  margin: 1rem auto;
}
.profile-detail h1 {
  text-transform: capitalize;
  color: #f3fcf0;
  color: var(--honeydew-white-color);
}

.profile-detail h4 {
  text-transform: uppercase;
  transition: all 1s linear;
}

.profile-detail p {
  font-size: 1.2rem;
}

.profile-detail h4:hover {
  transform: scale(1.3);
  font-weight: 600;
}

.social-icons {
  font-size: 2rem;
  margin: 0 0.4rem;
  color: black;
  transition: all 0.5s linear;
}

.social-icons:hover {
  color: #ffd23f;
  color: var(--sunglow-color);
}

@media screen and (min-width: 672px) {
  .profile-img {
    width: 20rem;
    height: 20rem;
  }
}

@media screen and (min-width: 672px) {
  .profile-center {
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    grid-gap: 1em;
    justify-items: center;
    align-items: center;
  }

  .bars {
    display: none;
  }
}

/* ## Education ## */

.education-center {
  width: 90vw;
  margin: 0 auto;
}

.education {
  border-radius: 3%;
  transition: all 0.5s linear;
  padding: 1rem;
  margin-bottom: 1rem;
}

.education:hover {
  transform: scaleX(1.02);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffd23f;
  color: white;
}

.education:hover h1 {
  color: white;
}
.education:hover p {
  color: white;
}

.education img {
  width: 100%;
  height: 200px;
  margin-top: 1rem;
  object-fit: contain;
  padding: 0 1rem;
}

.education h1 {
  text-align: center;
  color: rgb(75, 75, 75);
}

.education p {
  padding: 1rem;
  color: rgb(105, 104, 104);
}

.icons {
  color: #ee4266;
  color: var(--paradise-pink-color);
}

@media screen and (min-width: 765px) {
  .education-center {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .education {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 2rem);
    margin-bottom: 1rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .date {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 992px) {
  .education {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.333% - 2rem);
  }
}

/* ### contacts ### */

.contact {
  background: #540d6e;
  background: var(--blue-violet-color);
}

.contact h1 {
  color: white;
}

.contact-detail {
  color: white;
  font-size: 1rem;
  transition: all 1s linear;
}

.contact-detail:hover {
  transform: scale(1.1, 1.1);
  color: #ffd23f;
  color: var(--sunglow-color);
}

.contact-detail span {
  margin: 0.5rem;
}

.contact-center {
  max-width: 650px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  justify-items: center;
  align-items: center;
}

/* ## portfolio ## */

.portfolios {
  background: rgb(240, 240, 240);
  padding: 1rem;
}

.portfolio {
  margin-bottom: 1rem;
  background-color: white;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
}

.portfolio img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.portfolio h1 {
  color: #ee4266;
  color: var(--paradise-pink-color);
  max-width: 90%;
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem auto;
}

.portfolio p {
  margin: 0.5rem auto;
  text-align: center;
  max-width: 80%;
  font-size: 1.1rem;
  color: #5d5d5d;
}

.portfolio p a {
  text-decoration: none;
  padding-left: 0.2rem;
  font-style: italic;
}

.portfolio-center {
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.App {
  text-align: center;
}

